import React from 'react';
import '@ilama007/external-link/dist/index.css';
import { Col, Row } from 'react-flexbox-grid';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_isi.scss`.
 * */

export default function PatientIsi({ expanded }) {
  return (
    <section>
      <Row reverse={!expanded}>
        <Col md={expanded ? 12 : 6}>
          <p className="isi-h1">INDICATIONS AND USAGE</p>
          <p>
            Ephedrine Sulfate Injection is indicated for the treatment of
            clinically important hypotension occurring in the setting of
            anesthesia.
          </p>
        </Col>
        <Col md={expanded ? 12 : 6}>
          <p className="isi-h1">
            IMPORTANT SAFETY INFORMATION FOR EPHEDRINE SULFATE INJECTION
          </p>
          <p className="isi-h2">WARNINGS AND PRECAUTIONS</p>
          <p className="isi-h3">
            PRESSOR EFFECT WITH CONCOMITANT OXYTOCIC DRUGS
          </p>
          <p>
            Serious postpartum hypertension has been described in patients who
            received both a vasopressor (i.e., methoxamine, phenylephrine,
            ephedrine) and an oxytocic (i.e., methylergonovine, ergonovine).
            Some of these patients experienced a stroke. Carefully monitor the
            blood pressure of individuals who have received both ephedrine and
            an oxytocic.
          </p>
          <p className="isi-h3">TOLERANCE AND TACHYPHYLAXIS</p>
          <p>
            Data indicate that repeated administration of ephedrine can result
            in tachyphylaxis. Clinicians treating anesthesia-induced hypotension
            with Ephedrine Sulfate Injection should be aware of the possibility
            of tachyphylaxis and should be prepared with an alternative pressor
            to mitigate unacceptable responsiveness.
          </p>
          <p className="isi-h3">
            RISK OF HYPERTENSION WHEN USED PROPHYLACTICALLY
          </p>
          <p>
            When used to prevent hypotension, ephedrine has been associated with
            an increased incidence of hypertension compared with when ephedrine
            is used to treat hypotension.
          </p>
          <p className="isi-h2">ADVERSE REACTIONS</p>
          <p>
            Most common adverse reactions during treatment with ephedrine
            sulfate were nausea, vomiting, and tachycardia.
          </p>
          <p className="isi-h2">DRUG INTERACTIONS</p>
          <p className="isi-h3">
            Interactions that Augment the Pressor Effect
            <br />
            Oxytocin and oxytocic drugs, clonidine, propofol, monoamine oxidase
            inhibitors (MAOIs), and atropine
          </p>
          <p>
            Carefully monitor the blood pressure of individuals who have
            received both ephedrine and these drugs.
          </p>
          <p className="isi-h3">
            Interactions that Antagonize the Pressor Effect
            <br />
            α-adrenergic antagonists, β-adrenergic receptor antagonists,
            reserpine, quinidine, mephentermine
          </p>
          <p>
            Carefully monitor the blood pressure of individuals who have
            received both ephedrine and any of these drugs.
          </p>
          <p className="isi-h3">Other Drug Interactions</p>
          <p>
            <span className="bold">Guanethidine:</span> Ephedrine may inhibit
            the neuron blockage produced by guanethidine, resulting in loss of
            antihypertensive effectiveness. Monitor patient for blood pressor
            response and adjust the dosage or choice of pressor accordingly.
          </p>
          <p>
            <span className="bold">Rocuronium:</span> Ephedrine may reduce the
            onset time of neuromuscular blockade when used for intubation with
            rocuronium if administered simultaneously with anesthetic induction.
            Be aware of this potential interaction. No treatment or other
            interventions are needed.
          </p>
          <p>
            <span className="bold">Epidural anesthesia:</span> Ephedrine may
            decrease the efficacy of epidural blockade by hastening the
            regression of sensory analgesia. Monitor and treat the patient
            according to clinical practice.
          </p>
          <p>
            <span className="bold">Theophylline:</span> Concomitant use of
            ephedrine may increase the frequency of nausea, nervousness, and
            insomnia. Monitor patient for worsening symptoms and manage symptoms
            according to clinical practice.
          </p>
          <p>
            <span className="bold">Cardiac glycosides:</span> Giving ephedrine
            with a cardiac glycoside, such as digitalis, may increase the
            possibility of arrhythmias. Carefully monitor patients on cardiac
            glycosides who are also administered ephedrine.
          </p>
          <p className="isi-h2">OVERDOSAGE</p>
          <p>
            Overdose of ephedrine can cause a rapid rise in blood pressure. In
            the case of an overdose, careful monitoring of blood pressure is
            recommended. If blood pressure continues to rise to an unacceptable
            level, parenteral antihypertensive agents can be administered at the
            discretion of the clinician.
          </p>
        </Col>
      </Row>
      <p className="bold isi-please-see-pi">
        Please see{' '}
        <a
          href="https://endodocuments.com/EPHEDRINE_5/PI"
          target="_blank"
          rel="noreferrer"
        >
          Full Prescribing Information
        </a>{' '}
        for Ephedrine.
      </p>
    </section>
  );
}

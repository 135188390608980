import React, { useContext, useRef, useEffect } from 'react';
import { Logo } from 'components';
import Nav from './nav';
import PropTypes from 'prop-types';
import { appContext } from 'providers/appProvider';
import { useResizeObserver } from 'hooks';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import imgLogo from 'images/brand-logo.png';
import EphedrineWhiteLogoImg from 'images/logo-ephedrine-white.png';

const PatientHeader = ({ indication }) => {
  return (
    <div className="main-header-content-wrapper">
      <div className="gutter-all header-content">
        <Logo
          image={EphedrineWhiteLogoImg}
          url="/"
          className="brand-logo"
          imageAlt="Ephedrine logo"
        />
        <Nav indication={indication} />
      </div>
    </div>
  );
};

const HcpHeader = ({ indication }) => {
  return (
    <div className="main-header-content-wrapper">
      <div className="gutter-all header-content">
        <Logo image={imgLogo} url="/hcp" className="brand-logo" />
        <Nav indication={indication} />
      </div>
    </div>
  );
};

const HeaderUtilityNav = ({ className, ...props }) => {
  const { topOffset } = useContext(appContext);
  return (
    <div {...props} className={className}>
      <div className="header-utility-content-wrapper">
        <div className="gutter-all header-content">
          <ul className="utility-list">
            <li>
              <a
                rel="noopener noreferrer"
                href="https://endodocuments.com/EPHEDRINE_5/PI"
                target="_blank"
              >
                Prescribing Information
              </a>
            </li>
            <li>
              <AnchorLink
                href="#normal-chappy-isi"
                className="btn-expand-isi gutter-right"
                offset={topOffset}
              >
                Important Safety Information
              </AnchorLink>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                href="https://www.parpharm.com/contact-us/"
                target="_blank"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default function Header({ indication, ...props }) {
  const { updateHeaderHeight, magicshot, appConfigs } = useContext(appContext);
  const headerRef = useRef(null);
  const elementSize = useResizeObserver(headerRef);
  const isHeaderFixed = appConfigs.siteOptions.HEADER_TYPE === 'fixed';

  useEffect(() => {
    isHeaderFixed && updateHeaderHeight(elementSize.height);
  }, [elementSize.height]);

  return (
    <header
      {...props}
      className="header-wrapper"
      style={
        appConfigs.siteOptions.HEADER_TYPE === 'fixed' && !magicshot
          ? {
              position: 'fixed',
              width: '100vw',
            }
          : {}
      }
      ref={headerRef}
    >
      <HeaderUtilityNav className="header-utility-nav desktop" />
      {indication === 'hcp' && <HcpHeader indication={indication} />}
      {indication === 'patient' && <PatientHeader indication={indication} />}
    </header>
  );
}

Header.propTypes = {
  indication: PropTypes.string.isRequired,
};

import React, { useContext } from 'react';
import NavBar from '@ilama007/nav-bar';
import '@ilama007/nav-bar/dist/index.css';
import PropTypes from 'prop-types';
import HcpNav from './nav-contents/HcpNav';
import PatientNav from './nav-contents/PatientNav';
import { appContext } from '../../../../providers/appProvider';

export default function Nav({ indication }) {
  const { closeMobileNav, mobileNavOpen } = useContext(appContext);

  const closeHamburger = () => {
    closeMobileNav();
  };

  return (
    <div className="container-nav-hamburger">
      <NavBar mobileBreakPoint={767} triggerHamburgerClose={mobileNavOpen}>
        {indication === 'patient' && (
          <PatientNav triggerClose={closeHamburger} />
        )}
        {indication === 'hcp' && <HcpNav triggerClose={closeHamburger} />}
      </NavBar>
    </div>
  );
}

Nav.propTypes = {
  indication: PropTypes.string.isRequired,
};

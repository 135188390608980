import React, { Fragment, useState, useEffect, useContext } from 'react';
import StickyISI from '@ilama007/sticky-isi';
import { appContext } from 'providers/appProvider';
import PatientIsi from './isi-contents/PatientIsi';
import HcpIsi from './isi-contents/HcpIsi';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default function Isi({ footerRef, indication }) {
  const { appConfigs, topOffset, magicshot } = useContext(appContext);
  const [isSticky, setIsSticky] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [stickyHeight, setStickyHeight] = useState(
    appConfigs.siteOptions.STICKY_HEIGHT,
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setStickyHeight(160);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (appConfigs.siteOptions.ISI_OPTIONS.TYPE === 'sticky' && !magicshot) {
      setIsSticky(true);
    }
  }, []);

  appConfigs.siteOptions.STICKY_HEIGHT = stickyHeight;
  appConfigs.siteOptions.ISI_OPTIONS.STICKY_STYLES.height = stickyHeight;

  return (
    <Fragment>
      {isSticky ? (
        <StickyISI
          footerRef={footerRef}
          stickyHeight={appConfigs.siteOptions.STICKY_HEIGHT}
          stickyStyles={appConfigs.siteOptions.ISI_OPTIONS.STICKY_STYLES}
          className="isi"
          onFooterStateChange={result => {
            setIsExpanded(result);
          }}
        >
          <div className="isi-content-wrapper">
            <div className="isi-content gutter-all">
              {!isExpanded && (
                <AnchorLink
                  href="#normal-chappy-isi"
                  className="btn-expand-isi gutter-right"
                  offset={topOffset}
                >
                  <span className="btn-expand-text">Show More</span>
                  <span className="up-arrow-symbol">▲</span>
                </AnchorLink>
              )}

              {indication === 'patient' ? (
                <PatientIsi expanded={isExpanded} />
              ) : (
                <HcpIsi />
              )}
            </div>
          </div>
        </StickyISI>
      ) : (
        <div className="isi">
          <div className="isi-content-wrapper">
            <div className="isi-content gutter-all">
              {indication === 'patient' ? <PatientIsi /> : <HcpIsi />}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { appContext } from 'providers/appProvider';

import AnchorLink from 'react-anchor-link-smooth-scroll';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_nav.scss`.
 * */

export default function PatientNav({ triggerClose }) {
  const { topOffset } = useContext(appContext);

  const handleClick = () => {
    triggerClose && triggerClose();
  };

  return (
    <nav className="main-nav patient-nav">
      <Link
        to="/workflow-scenarios"
        className="nav-item"
        activeClassName="active"
        onClick={handleClick}
      >
        Workflow Scenarios
        <div className="nav-underline"></div>
      </Link>
      <a
        href="/pdfs/Ephedrine_RTU_PI.pdf"
        className="nav-item mobile-only"
        target="_blank"
      >
        Prescribing Information
      </a>
      <AnchorLink
        href="#normal-chappy-isi"
        className="nav-item mobile-only btn-expand-isi gutter-right"
        offset={topOffset}
        onClick={handleClick}
      >
        Important Safety Information
      </AnchorLink>
      <a
        rel="noopener noreferrer"
        href="https://www.parpharm.com/contact-us/"
        className="nav-item mobile-only"
        target="_blank"
      >
        Contact
      </a>
    </nav>
  );
}

/**
 * triggerClose is the methid propagated from the parent Nav component
 * It needs to get triggered sometimes when Link refers to the same page and
 * we need to manually trigger hamburger close
 */

PatientNav.propTypes = {
  triggerClose: PropTypes.func,
  onNavOpen: PropTypes.func,
  onNavClose: PropTypes.func,
};

import React, { useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Logo } from 'components';
import PropTypes from 'prop-types';
import { appContext } from 'providers/appProvider';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import FooterLogo from 'images/logo-ephedrine-colored.png';
import ParLogoImg from 'images/logo-par.png';
import EndoLogoImg from 'images/logo-endo.png';
import TruLogoImg from 'images/logo-tru.png';

const PatientFooter = () => {
  const { appConfigs } = useContext(appContext);

  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <Row className="align-items-center">
          <Col xs={12} md={7} lg={7}>
            <div className="container-footer-logo">
              <div className="logo-divider-wrapper desktop-only">
                <Logo
                  image={FooterLogo}
                  url="/"
                  className="footer-logo"
                  imageAlt="Ephedrine Sulfate Injection logo"
                />
              </div>
              <div className="logo-divider-wrapper">
                <Logo
                  url="https://www.endo.com/products-and-capabilities/focus-areas/#sterile-injectables"
                  image={ParLogoImg}
                  className="footer-logo"
                  target="_blank"
                  imageAlt="PAR Logo"
                  rel="noopener noreferrer"
                />
              </div>
              <div className="logo-divider-wrapper">
                <Logo
                  image={EndoLogoImg}
                  className="footer-logo"
                  imageAlt="Endo Injectable solutions logo"
                  url="https://www.endo.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              </div>
              <div className="logo-divider-wrapper">
                <Logo
                  url="https://www.trudelivery.com/"
                  target="_blank"
                  image={TruLogoImg}
                  className="footer-logo"
                  imageAlt="TRUDelivery logo"
                  rel="noopener noreferrer"
                />
              </div>
            </div>
          </Col>

          <Col xs={12} md={5} lg={5}>
            <div className="inner-content">
              <p className="copyright-text">
                © 2024 Endo, Inc. or one of its affiliates. All rights reserved.
              </p>
              <p className="copyright-text">
                <span className="bold">
                  {appConfigs.metaData.PATIENT_JOB_CODE}/August 2024
                </span>
                &nbsp;&nbsp;
                <Link target="_blank" href="https://www.endo.com">
                  www.endo.com
                </Link>
                {' | '}
                <Link target="_blank" href="https://www.TruDelivery.com">
                  www.TruDelivery.com
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const FooterUtilityNav = ({ className, ...props }) => {
  const { topOffset } = useContext(appContext);

  return (
    <div {...props} className={className}>
      <div className="gutter-all footer-content">
        <Row center="xs">
          <Col xs={12}>
            <div className="container-footer-logo logo-ephedrine mobile-only">
              <Logo
                image={FooterLogo}
                imageAlt="Ephedrine Sulfate Injection logo"
                url="/"
                className="footer-logo"
              />
            </div>
          </Col>

          <Col xs={12} md={11.5} lg={9} xl={8}>
            <ul className="utility-list">
              <li>
                <Link to="/workflow-scenarios">Workflow Scenarios</Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://endodocuments.com/EPHEDRINE_5/PI"
                >
                  Prescribing Information
                </a>
              </li>
              <li>
                <AnchorLink
                  href="#normal-chappy-isi"
                  className="btn-expand-isi gutter-right"
                  offset={topOffset}
                >
                  Important Safety Information
                </AnchorLink>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.parpharm.com/contact-us/"
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className="ot-sdk-show-settings"
                  style={{ cursor: 'pointer' }}
                >
                  Cookies Settings
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function Footer({ indication, ...props }) {
  return (
    <footer {...props} className="footer-wrapper">
      <FooterUtilityNav className="footer-utility-nav" />
      {indication === 'patient' && <PatientFooter />}
    </footer>
  );
}

Footer.propTypes = {
  indication: PropTypes.string.isRequired,
};
